import 'fslightbox';
import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, Keyboard, EffectFade, Scrollbar, EffectCoverflow, Controller, Thumbs } from "swiper/modules";

export const slider = () => {

    if (document.querySelector('.simpleSlider')) {
        const slider = document.querySelector('.simpleSlider')
        const swiper = new Swiper(slider, {
            modules: [Scrollbar, EffectFade],
            loop: true,
            slidesPerView: 3.5,
            fadeEffect: {
                crossFade: true,
            },
            scrollbar: {
                el: document.querySelector('.slider-scrollbar'),
                draggable: true,
            },
            watchSlidesVisibility: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                // mobile + tablet - 320-990
                320: {
                  slidesPerView: 'auto'
                },
                // desktop >= 991
                991: {
                  slidesPerView: 3.5
                }
              }

        });

    }
};