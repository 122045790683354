export const burger = () => {
  AOS.init();

  //== Start add burger-menu ==//
  const header = document.querySelector('.header')
  const burgerBtnWrapper = document.querySelector('.header__burger-wrapper')
  const burgerBtn = document.querySelector('.header__burger')
  const html = document.querySelector('html')
  const menuItems = document.querySelectorAll('.header__wp a')
  const link = document.querySelector('.header__button')
  const burgerMenu = document.querySelector('.header__main')
  const open = document.querySelector('.header__burger-open')
  const close = document.querySelector('.header__burger-close')
  menuItems.forEach(item => {
    item.addEventListener('click', () => {
      header.classList.remove('active')
      burgerMenu.classList.remove('open')
      burgerBtn.classList.remove('open')
      close.style.display = 'none'
      open.style.display = 'block'
      changeHtmlOverflow('remove')
    })
  })

  link.addEventListener('click', () => {
    burgerMenu.classList.remove('open')
    burgerBtn.classList.remove('open')
    header.classList.remove('active')
    close.style.display = 'none'
    open.style.display = 'block'
    changeHtmlOverflow('remove')
  })

  burgerBtnWrapper.addEventListener('click', () => {
    burgerMenu.classList.toggle('open')
    burgerBtn.classList.toggle('open')
    header.classList.toggle('active')
    open.style.display = 'none'
    close.style.display = 'block'

    if (burgerMenu.classList.contains('open')) {
      changeHtmlOverflow('add')
      close.style.display = 'block'
    } else {
      changeHtmlOverflow('remove')
      close.style.display = 'none'
      open.style.display = 'block'
    }
  })
  //== End add burger-menu ==//


  const changeHtmlOverflow = (action) => {
    if (action === 'add') {
      html.style.overflow = 'hidden'
    }
    if (action === 'remove') {
      html.style.overflow = 'inherit'
    }
  }


  //== header scroll ==//


  window.addEventListener("scroll", function () {
    if (window.scrollY > 50) {
      header.classList.add("header__dark");
    } else {
      header.classList.remove("header__dark");
    }
  });

  //== header scroll ==//

  //==  switcher ==//

  const current = document.querySelector(".header__current");
  const select = document.querySelector(".header__select");
  current.addEventListener("click", () => {
    select.classList.toggle("active");
    current.classList.toggle("active");
  });

  document.addEventListener("click", (e) => {
    if (!e.composedPath().includes(select) && !e.composedPath().includes(current)) {
      select.classList.remove("active");
      current.classList.remove("active");
    }
  })

  //==  switcher ==//

};