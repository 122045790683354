export const form = () => {
    if (document.querySelector('.contact')) {
        const popup = document.querySelector('.form__popup-wrapper')
        const close = document.querySelector('.form__popup-close')
        const button = document.querySelector('.form__popup-button')

        document.addEventListener('wpcf7mailsent', () => {
            popup.classList.add('active')
        });
        close.addEventListener('click', () => {
            popup.classList.remove('active')
        });
        button.addEventListener('click', () => {
            popup.classList.remove('active')
        });
    }

}