import 'fslightbox';
// import { isMobile } from "../../is-mobile.js";
import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, Keyboard, EffectFade, Scrollbar, EffectCoverflow, Controller, Thumbs } from "swiper/modules";

export const testimonial = () => {

    if (document.querySelector('.mySwiperTest')) {
        const slider = document.querySelector('.mySwiperTest')
        const sliderWrapper = slider.querySelector(".swiper-wrapper");
        let sliderItems = slider.querySelectorAll(".swiper-slide");

        if (sliderWrapper && sliderItems.length > 0) {
            let slidesCount = sliderItems.length;

            if (slidesCount <= 8) {
                let repeat = Math.ceil((8 / slidesCount) * 2);
                if (repeat > 0) {
                    for (let i = 0, n = repeat; i < n; i++) {
                        sliderItems.forEach((item) => {
                            const swiperItemNew = document.createElement("div");
                            swiperItemNew.classList.add("swiper-slide");
                            swiperItemNew.innerHTML = item.innerHTML;

                            sliderWrapper.append(swiperItemNew);
                        });
                    }
                }
            }

            const swiper = new Swiper(slider, {
                modules: [Scrollbar, Autoplay],
                spaceBetween: 20,
                loop: true,
                centeredSlides: false,
                slidesPerView: "auto",
                speed: 8000,
                allowTouchMove: false,
                autoplay: {
                    delay: 0,
                    disableOnInteraction: false,
                },
            });
            let transformValue;
            let slidePercent;
            let durationPercent;

            sliderWrapper.addEventListener("mouseenter", function () {
                // if (!isMobile.any()) {


                transformValue = window.getComputedStyle(sliderWrapper).getPropertyValue("transform");
                const matrix = transformValue.match(/^matrix\((.+)\)$/);
                if (matrix && matrix[1]) {
                    const matrixValues = matrix[1].split(", ");
                    if (matrixValues.length >= 6) {
                        const translateX = parseFloat(matrixValues[4]);
                        swiper.autoplay.stop();
                        transformValue = this.style.transform;
                        this.style.transitionDuration = "0ms";
                        this.style.transform = "translate3d(" + translateX + "px, 0px, 0px)";
                    } else {
                        console.error("Matrix transformation does not contain the expected values:", transformValue);
                    }
                } else {
                    console.error("Unable to extract translateX value from transform property:", transformValue);
                }
                sliderItems = slider.querySelectorAll(".swiper-slide");
                const dir = slider.getAttribute("dir");
                if (dir && dir === "rtl") {
                    slidePercent =
                        ((window.innerWidth - sliderItems[swiper.activeIndex].getBoundingClientRect().right) * 100) / (sliderItems[swiper.activeIndex].offsetWidth + swiper.params.spaceBetween);
                } else {
                    slidePercent = (sliderItems[swiper.activeIndex].getBoundingClientRect().left * 100) / (sliderItems[swiper.activeIndex].offsetWidth + swiper.params.spaceBetween);
                }
                durationPercent = (swiper.params.speed * slidePercent) / 100;
            }
                // }
            );

            sliderWrapper.addEventListener("mouseleave", function () {
                this.style.transitionDuration = `${durationPercent}ms`;
                this.style.transform = transformValue;
                swiper.autoplay.start();
            });

        }
    }
};