// import { maptilerLayer } from '@maptiler/leaflet-maptilersdk';

export const map = () => {
    if (document.querySelector('.map')) {
        const map = L.map('map').setView([51.12434, 17.04560], 14);
        L.tileLayer('https://{s}.tile.jawg.io/jawg-dark/{z}/{x}/{y}.png?access-token=V2vbl0iHjRpXf35zx8BEL9MVx2DDmmHMNkdP6M0V6qLvuqOAXc3PfEEfhOOI8gSI', {
            maxZoom: 22,
            attribution: '&copy; <a href="https://www.jawg.io/">Jawg</a>'
        }).addTo(map);

        const svgIcon = L.divIcon({
            className: 'custom-div-icon',
            html: `
                <svg width="42" height="46" viewBox="0 0 42 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M35.7861 6.55528C33.713 4.50393 31.137 2.89902 28.0527 1.74054C24.9683 0.582064 21.37 0 17.2745 0H11.5577H10.3149H0V46H10.3149H11.5577H17.2745C21.3756 46 24.9683 45.3671 28.0527 44.1069C31.137 42.841 33.7186 41.1457 35.7861 39.0096C37.8593 36.8735 39.4128 34.4717 40.4465 31.7988C41.4803 29.1258 42 26.402 42 23.6273V22.2597C42 19.2364 41.4803 16.3769 40.4465 13.6813C39.4128 10.9744 37.8593 8.60663 35.7861 6.55528ZM34.5433 31.471L23.2567 28.8658L19.8673 39.9477L16.478 28.8658L5.19136 31.471L13.0886 22.9943L5.19136 14.5177L16.478 17.1229L19.8673 6.04103L23.2567 17.1229L34.5433 14.5177L26.6461 22.9943L34.5433 31.471Z" fill="white"/>
    </svg>
            `,
            iconSize: [42, 46],
            iconAnchor: [25, 25],
        });

        const marker = L.marker([51.12908, 17.04683], { icon: svgIcon }).addTo(map);
    }

}

